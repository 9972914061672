@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Merriweather:wght@300;900&family=Open+Sans:wght@800&display=swap');


::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.nav {
  font-family: 'Merriweather', serif;
  font-size:18px;
}
.bg-container {
  width: 100vw;
  height:94vh;
  background-image: url('./black.jpeg');
  background-size: cover;
  align-items: center;
  justify-content: center;
  display:flex;
  overflow-y:hidden;
}


#hideMe {
  -moz-animation: cssAnimation 0s ease-in 4.5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 4.5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 4.5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 4.5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.about {
  background-color: #202020;
  height:94vh;
  white-space: normal;
}

.profile {
  width:500px;
  filter: drop-shadow(7px 7px 5px black);
  
}


.section-left {
  display:flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid yellow;*/
  
}

.section-right{
  font-family: 'Merriweather', serif;
  display: flex;
  color:white;
  justify-content: center;
  letter-spacing: 2px;
  text-align: center;
  align-items:center;
  padding-bottom:50px;
}

.section-right h1 {
  font-size:60px;
  padding-bottom:20px;
}

.section-right h4 {
  font-size:20px;
  padding-bottom:15px;
  font-size:25px;
}
.rower {
  height:100%;
  padding:0;
  width:100%
}

.icons {
  padding-top: 10px;
  display:flex;
  column-gap: 1.3rem;
  justify-content: center;
}

.first-item{
  height:94vh;
}

.first-pic {
  position: absolute;
  z-index: 0;
  display:flex;
  justify-content: center;
}

.second-pic {
  position: absolute;
  z-index: 5;
  width:840px;
  height:500px;
    /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    /* position the left edge of the element at the middle of the parent */
  padding-top:30px;
    transform: translate(-50%, 0);
}
.caption {
  font-family: 'Merriweather', serif;
}

.form-width {
  width:550px;
  color:#202020;
  font-family: 'Merriweather', serif;
  
}

.text-area {
  resize: none;
}

.button-color {
  background-color: lightgoldenrodyellow !important;
}

.contact-container {
  background-image: url('../src/images/light-grey-05.jpeg');
  background-size: cover;
  /*background-color: #505050;*/
  height:94vh;
  display:flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .profile {
    width: 300px;
    filter: drop-shadow(7px 7px 5px black);
  }

  .about {
    background-color: #202020;
    height: 150vh;
  }

  .section-right {
    padding-bottom:25vh;
  }

  .form-width {
    width:300px;
  }
  .contact-container {
    height:95vh;
  }
  .first-pic {
    height:800px;
  }
  .second-pic {
      width: 300px;
      height: 300px;
      padding-bottom:60px
      
  }
  .caption {
    font-size: 15px;
  }

  
}
@media only screen and (max-width: 900px) and (min-width: 500px){
  .profile {
    width: 400px;
    filter: drop-shadow(7px 7px 5px black);
  }
    .contact-container {
      height: 98vh;
    }
}

@media only screen and (min-height: 1000px) {
  .contact-container {
    height: 100vh;
  }
  .about {
    height: 100vh;
  }
  .bg-container {
    height: 100vh;
  }
  .first-item {
    height:96vh;
  }
  .second-pic {
    width:1600px;
    height:900px;
  }
  ::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }
  
}

